.collection-category-swiper .swiper {
    width: 100%;
    height: 100%;
}

.collection-category-swiper .swiper-pagination-bullet {
    background: #ffffffb3;
}

.collection-category-swiper .swiper-pagination-bullet-active {
    background-color: rgba(255, 255, 255, 0.2) !important;
}

.collection-category-swiper .swiper-button-next,
.swiper-button-prev {
    color: rgba(255, 255, 255, 0.2) !important;
}

.collection-category-swiper .swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 16px;
}

.collection-category-swiper .swiper-slide {
    text-align: center;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.collection-category-swiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.collection-category-swiper .swiper-button-prev,
.collection-category-swiper .swiper-button-next {
    width: 30px !important;
    height: 30px !important;
    color: white !important;
    background-color: rgba(255, 255, 255, 0.2) !important;
    position: absolute;
    top: 50%;
    z-index: 2;
    border-radius: 50%;
    transform: translateY(-50%);
    box-shadow: rgb(0 0 0 / 15%) 0px 4px 10px;
}

.collection-category-swiper .swiper-button-prev:hover,
.collection-category-swiper .swiper-button-next:hover {
    background-color: rgba(255, 255, 255, 0.3);
    box-shadow: rgb(0 0 0 / 25%) 0px 4px 10px;
}

/* styles.css */
.swiper-button-next,
.swiper-button-prev {
    color: red;
    /* Custom color for navigation buttons */
}

.swiper-pagination-bullet {
    background: red;
    /* Custom color for pagination bullets */
}

.swiper-pagination-bullet-active {
    background: red;
    /* Custom color for active pagination bullet */
}