.previewSwiper .swiper {
    width: 100%;
    height: 100%;
}
.previewSwiper .swiper-pagination-bullet-active {
    background-color: #944DFF
}
.previewSwiper .swiper-button-next, .swiper-button-prev {
    color: #944DFF
}
.previewSwiper .swiper-button-next:after, .swiper-button-prev:after {
     font-size: 20px;
}
 .album-slider .swiper-slide {
     max-height: 450px;
}
 .previewSwiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
