html, body {

    padding: 0;

    margin: 0;

}

a {
    text-decoration: none;
}

@keyframes glow {
    0% { 
        box-shadow: 0px 30px 80px rgb(138 38 255 / 40%); 
    
    }
    50% { 
        box-shadow: 0px 30px 80px rgb(138 38 255 / 20%); 
      
    }
    100% { 
        box-shadow: 0px 30px 80px rgb(138 38 255 / 40%); 
        
    }
}

@font-face {
    font-family: 'Quantico';
    font-weight: 400;
    src: url(/src/fonts/Quantico-Regular.ttf);
}

@font-face {
    font-family: 'Quantico';
    font-weight: 700;
    src: url(/src/fonts/Quantico-Bold.ttf);
}

@font-face {
    font-family: 'Open Sans';
    src: url(/src/fonts/OpenSans-VariableFont_wdth\,wght.ttf);
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url(/src/fonts/OpenSans-Italic-VariableFont_wdth\,wght.ttf);
    font-style: italic;
}

@font-face {
    font-family: 'Manrope';
    src: url(/src/fonts/Manrope-VariableFont_wght.ttf);
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url(/src/fonts/Montserrat-Italic.ttf);
    font-style: italic;
}


.btn-blue{
    background-color: #954dff !important;
    padding: 15px 40px !important;
    color: #FFFFFF !important;
}

.hover-shadow{
    box-shadow: none; 
}

.hover-shadow:hover{
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.3),0px 4px 5px 0px rgba(0,0,0,0.34),0px 1px 10px 0px rgba(0,0,0,0.32);
}

.scroll-width{
    max-width: 100%;
    display: block;
}

.a-link{
    color:#333;
}

.a-link:hover{
    color:#333;
    text-decoration: underline;
}