.storySwiper .swiper {
    width: 100%;
    height: auto;
}

.storySwiper .swiper-slide {
    text-align: center;
    width: max-content;
    height: auto;
    font-size: 18px;
    background: #fff;
}

.storySwiper .swiper .swiper-slide {
    height: auto;
    line-height: 300px;
}

.storySwiper .swiper .swiper-slide:nth-child(2n) {
    height: 500px;
    line-height: 500px;
}

