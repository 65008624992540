.featuredCollections {
    width: 100%;
    height: 100%;
}

.featuredCollections .swiper-pagination-bullet {
    background: rgba(255, 255, 255, 0.12);
}

.featuredCollections .swiper-pagination-bullet-active {
    background-color: rgba(255, 255, 255, 1);
}
.featuredCollections .swiper-button-next, .swiper-button-prev {
    color: rgba(255, 255, 255, 1);
}
.featuredCollections .swiper-button-next:after, .swiper-button-prev:after {
    font-size: 20px;
    font-weight: 700;
}
.featuredCollections .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

 .featuredCollections .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.featuredCollections .swiper-button-prev, .featuredCollections .swiper-button-next {
    width: 40px;
    height: 40px;
    color: white;
    background-color: rgba(255, 255, 255, 0);
    position: absolute;
    top: 50%;
    z-index: 2;
    border-radius: 50%;
    box-shadow: rgb(0 0 0 / 0%) 0px 4px 10px;
    transition: all 0.3s ease-in-out;
}
.featuredCollections .swiper-button-prev:hover, .featuredCollections .swiper-button-next:hover {
    background-color: rgba(255, 255, 255, 0.09);
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 10px;
    transform: scale(1.05);
}

@media only screen and (max-width: 1000px) {
    .featuredCollections .swiper-slide img {
       height: 400px !important;
        border-radius: 0px;
    }
    .featuredCollections .swiper-button-prev, .featuredCollections .swiper-button-next {
        display: none;
    }
}