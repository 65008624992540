
.desktopCollectionSlider {
    width: 100%;
    height: 510px;
    margin: 20px auto 0px;
}

.desktopCollectionSlider .swiper-slide {
    opacity: .5;
    transform: scale(.84);
    transition: all .3s ease-in-out;
}


.desktopCollectionSlider .swiper-slide.swiper-slide-active {
    transform: scale(1) !important;
    opacity: 1 !important;
}

.desktopCollectionSlider .swiper-backface-hidden .swiper-slide.swiper-slide-active {
    transform: scale(1) translateZ(0) !important;
}

.desktopCollectionSlider .swiper-image {
    position: relative;
    width: 100%;
    padding-top: var(--swiper-image-ratio);
}

.desktopCollectionSlider .swiper-image .image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

    .desktopCollectionSlider .swiper-button-next,
    .desktopCollectionSlider .swiper-button-prev {

        margin-top: 0;
        width: 40px;
        height: 40px;
        background-color: #1B1E2F;
        position: absolute;
        top: 44%;
        z-index: 2;
        border-radius: 50%;
        box-shadow: rgb(0 0 0 / 15%) 0px 4px 10px;
    }

.desktopCollectionSlider .swiper-button-prev:hover, .desktopCollectionSlider .swiper-button-next:hover {
    background-color: #944dff;
    box-shadow: rgb(0 0 0 / 25%) 0px 4px 10px;

}

.desktopCollectionSlider .swiper-button-next::after,
.desktopCollectionSlider .swiper-button-prev::after {
    color: #fff;
    font-size: 12px;
}

.desktopCollectionSlider .swiper-button-next {
    transform: translate(50%, -70%);
    right: calc((100% - var(--swiper-width)) / 2);
}

.desktopCollectionSlider .swiper-button-prev {
    transform: translate(-50%, -70%);
    left: calc((100% - var(--swiper-width)) / 2);
}

.desktopCollectionSlider .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #944dff;
    opacity: 1;
    background: rgba(51, 54, 69, 0.2);
}

.desktopCollectionSlider .swiper-pagination-bullet-active {
    color: #fff;
    background: #944dff;
    width: 15px;
    height: 15px;
}
