.mobileSwiper {
    width: 200px;
    height: 300px;
}

.mobileSwiper .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    background-color: #1B1E2F;
    border-radius: 8px;
    box-shadow: #944dff 0px 0px 10px 0px;
}