.owned-nfts-slider .swiper {
    width: 100%;
    height: 100%;
}

.owned-nfts-slider .swiper-pagination-bullet {
    background: #ffffffb3;
}

.owned-nfts-slider .swiper-pagination-bullet-active {
    background-color: #944DFF
}

.owned-nfts-slider .swiper-button-next,
.swiper-button-prev {
    color: #944DFF
}

.owned-nfts-slider .swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 20px;
}

.owned-nfts-slider .swiper-slide {
    text-align: center;
    font-size: 18px;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.owned-nfts-slider .swiper-slide img {
    display: block;
    width: 100%;
    height: 130px;
    object-fit: cover;
}

.owned-nfts-slider .swiper-button-prev,
.collection-category-swiper .swiper-button-next {
    width: 40px;
    height: 40px;
    color: white;
    background-color: #944dff;
    position: absolute;
    top: 50%;
    z-index: 2;
    border-radius: 50%;
    transform: translateY(-50%);
    box-shadow: rgb(0 0 0 / 15%) 0px 4px 10px;
}

.owned-nfts-slider .swiper-button-prev:hover,
.collection-category-swiper .swiper-button-next:hover {
    background-color: #944dff;
    box-shadow: rgb(0 0 0 / 25%) 0px 4px 10px;

}